.actions-icons {
    display: flex;
    gap: 0.75rem;
}

.table-toolbar{
    flex-wrap: wrap;
    gap:0.75rem;
}

.question-column{
    width:85%;
}

.student-response-column{
    width:5%;
    text-align: center;
}

.answer-column{
    width:10%;
    text-align: right;
}

.email-column{
    width:50%;
}

.button-column{
    width:50%;
    text-align: right;
}

/* Files table */
.file-table{
    box-sizing: border-box;
    table-layout: auto;
    min-width:820px;
}

.file-table thead th:nth-of-type(1) {
  width: 1.5rem;
}

.file-table thead th:nth-of-type(2) {
  width: 50%;
  text-align: left; /* Left-align File Name header */
}

.file-table tbody td:nth-of-type(3),
.file-table tbody td:nth-of-type(4) {
  text-align: center; /* Center-align Active and Weight column cells */
}

.file-table tbody td:nth-of-type(4) {
  text-align: right; /* Right-align Added on column cells */
}

/* Default first column values are already left-aligned */

/* Ensure responsive behavior is maintained */
@media (max-width: 820px) {
  .file-table {
    width: 100%;
  }
}

@media (max-width: 540px) {
    .file-table{
        width:100%;
    }

    .question-column{
        width:70%;
    }

    .answer-column{
        width:30%;
        text-align: right;
    }
}